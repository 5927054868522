import { AspectRatio, Box, Container, Flex } from "@chakra-ui/react";
import { BackgroundGradient, EmailCaptureForm } from "../../../common";
import { global } from "../../../../public/content/membership";
import { constants } from "../../../../util/constants";

const EmailCaptureTile = () => {
    const { aspectRatio, backgroundSettings, containerSize } = constants;
    const gradient =
        "linear-gradient(6deg, #5c888d 14%, rgba(255, 255, 255, 0) 92%)";

    return (
        <AspectRatio ratio={aspectRatio.normal}>
            <Box
                bgImage={global.emailCapture.backgroundImage.src}
                {...backgroundSettings}
            >
                <BackgroundGradient gradient={gradient} />
                <Container h="100%" w="100%" maxW={containerSize}>
                    <Flex
                        h="100%"
                        w="100%"
                        direction={{ base: "column" }}
                        alignItems={{ base: "center", md: "flex-start" }}
                        justifyContent={{ base: "center", md: "flex-end" }}
                    >
                        <Box sx={{ zIndex: 100 }} mb={{ base: 0, md: "5%" }}>
                            <EmailCaptureForm
                                headline={global.emailCapture.headline}
                                copy={global.emailCapture.copy}
                            />
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </AspectRatio>
    );
};

export default EmailCaptureTile;
