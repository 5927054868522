import { Box, Container, Flex, Heading, Image, Link, List, ListItem, useStyleConfig } from "@chakra-ui/react";
import { FooterIcons } from "..";
import { constants } from "../../../../../util/constants";
import { footer } from "../../../../../public/content/footer";

const FooterContainer = () => {
    const { containerSize } = constants;
    const styles = useStyleConfig("Footer");

    const footerLogo = (
        <Image
            w="100%"
            src="/images/vacayou-voyager-logo.svg"
            px={{ base: 0, md: "12px" }}
            py={{ base: 0, sm: "12px", md: 0 }}
        />
    );

    const footerSubsections = footer.subsections.map((subsection, i) => {
        return (
            <Box
                key={i}
                w={{ base: "100", md: "33%" }}
                mb={{
                    base: i < footer.subsections.length - 1 ? "16px" : 0,
                    md: 0,
                }}
            >
                <Heading
                    as="h6"
                    size="xs"
                    mb={1}
                    sx={{
                        fontFamily: "Lato",
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#e8e9e9",
                    }}
                    textTransform="uppercase"
                >
                    {subsection.header}
                </Heading>
                <List
                    spacing={1}
                    sx={{
                        listStyle: "none",
                        webkitMarginStart: "0 !important",
                        marginInlineStart: "0 !important",
                    }}
                >
                    {subsection.links.map((link, i) => (
                        <ListItem key={i} fontSize={{ base: "14px", md: "13px" }} pb={0}>
                            <Link href={link.url} target="_blank">
                                {link.text}
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    });

    return (
        <Box as="footer" sx={styles} pt={8} pb={10} flexShrink={0}>
            <Container maxW={containerSize}>
                <Flex h="100%" w="100%" direction={{ base: "column", md: "row" }}>
                    <Flex
                        direction={{
                            base: "column",
                            sm: "row",
                            md: "column",
                        }}
                        justifyContent={{
                            base: "space-between",
                            md: "flex-start",
                        }}
                        alignItems={{ base: "center", sm: "flex-start" }}
                        mr={{ base: 0, md: "30px", lg: "50px", xl: "60px" }}
                        mb={{ base: "30px", md: 0 }}
                    >
                        <Flex w={{ base: "170px", md: "100%" }}>{footerLogo}</Flex>
                        <Flex w={{ base: "160px", md: "100%" }}>
                            <FooterIcons />
                        </Flex>
                    </Flex>
                    <Flex direction={{ base: "column", md: "row" }} maxW="600px" flexGrow={1}>
                        {footerSubsections}
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
};

export default FooterContainer;
