// DO NOT DELETE THIS SECTION
export const navigation = {
    logo: {
        image: {
            src: "/images/vacayou-voyager-logo.svg",
            alt: "Vacayou Voyager Club",
        },
        link: {
            label: "",
            href: "/",
            type: "internal-link",
        },
    },
    menu: {
        loggedOut: [
            {
                link: {
                    label: "Home",
                    href: "/",
                    type: "internal-link",
                },
            },
            {
                link: {
                    label: "Join Now",
                    href: "/sign-up",
                    type: "sign-up-link",
                },
            },
            {
                link: {
                    label: "Log In",
                    href: "/login",
                    type: "internal-link",
                },
            },
        ],
        loggedIn: {
            standard: [
                {
                    link: {
                        label: "Home",
                        href: "/",
                        type: "internal-link",
                    },
                },
                {
                    link: {
                        label: "Join Now",
                        href: "/sign-up",
                        type: "sign-up-link",
                    },
                },
                {
                    header: "Account",
                    subMenu: [
                        {
                            link: {
                                label: "Settings",
                                href: "/account/settings",
                                type: "internal-link",
                            },
                        },
                        // {
                        //     link: {
                        //         label: "Preferences",
                        //         href: "/account/preferences",
                        //         type: "internal-link",
                        //     },
                        // },
                        // {
                        //     link: {
                        //         label: "Wish List",
                        //         href: "/account/wish-list",
                        //         type: "internal-link",
                        //     },
                        // },
                        // {
                        //     link: {
                        //         label: "Trips & Itineraries",
                        //         href: "/account/trips",
                        //         type: "internal-link",
                        //     },
                        // },
                        {
                            link: {
                                label: "Log Out",
                                href: "/account/logout",
                                type: "internal-link",
                            },
                        },
                        {
                            link: {
                                label: "833.822.2968",
                                href: "tel:+18338222968",
                                type: "action-link",
                            },
                        },
                        {
                            link: {
                                label: "Chat With Us",
                                href: "#hs-chat-open",
                                type: "action-link",
                            },
                        },
                    ],
                },
            ],
            voyager: [
                {
                    link: {
                        label: "Dashboard",
                        href: "/dashboard",
                        type: "internal-link",
                    },
                },
                {
                    header: "Member Benefits",
                    subMenu: [
                        {
                            link: {
                                label: "Exclusive Getaways",
                                href: "/trips",
                                type: "internal-link",
                            },
                        },
                        {
                            link: {
                                label: "Wellnes Hotels",
                                href: "/hotels",
                                type: "internal-link",
                            },
                        },
                        {
                            link: {
                                label: "Member Perks",
                                href: "/perks",
                                type: "internal-link",
                            },
                        },
                        {
                            link: {
                                label: "Videos",
                                href: "/videos",
                                type: "internal-link",
                            },
                        },

                        {
                            link: {
                                label: "Wellness This Week",
                                href: "/wellness-this-week",
                                type: "internal-link",
                            },
                        },
                    ],
                },
                {
                    header: "Account",
                    subMenu: [
                        {
                            link: {
                                label: "Settings",
                                href: "/account/settings",
                                type: "internal-link",
                            },
                        },
                        // {
                        //     link: {
                        //         label: "Preferences",
                        //         href: "/account/preferences",
                        //         type: "internal-link",
                        //     },
                        // },
                        // {
                        //     link: {
                        //         label: "Wish List",
                        //         href: "/account/wish-list",
                        //         type: "internal-link",
                        //     },
                        // },
                        // {
                        //     link: {
                        //         label: "Trips & Itineraries",
                        //         href: "/account/trips",
                        //         type: "internal-link",
                        //     },
                        // },
                        {
                            link: {
                                label: "Log Out",
                                href: "/account/logout",
                                type: "internal-link",
                            },
                        },
                        {
                            link: {
                                label: "833.822.2968",
                                href: "tel:+18338222968",
                                type: "external-link",
                            },
                        },
                        {
                            link: {
                                label: "Chat With Us",
                                href: "#hs-chat-open",
                                type: "action-link",
                            },
                        },
                    ],
                },
            ],
        },
    },
};

// TODO remove this after moving to Prismic
export const global = {
    emailCapture: {
        headline: "Stay Connected",
        copy: "Sign up to get the newest packages and latest updates delivered right to your inbox.",
        backgroundImage: {
            src: "/images/global/email-capture@3x.png",
        },
    },
};

// TODO remove this after moving to Prismic
export const trips = {
    sections: {
        hero: {
            headline: "Exclusive Getaways",
            copy: "Our exclusive roster of carefully vetted partners means we can provide unique experiences and unforgettable journeys that fit your lifestyle.",
            backgroundImage: "https://i.assetzen.net/i/YFT3us8FsTX8/w:1284/h:856/q:70.jpg",
        },
    },
};
