import React from "react";
import { useRouter } from "next/router";
import { useAuth } from "@/services/hooks/useAuth";
import { useApi } from "@/services/hooks/useApi";
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { CgProfile } from "react-icons/cg";

const NavigationDropdown = ({ navigation, userType, isB2b, isDesktop }) => {
    const router = useRouter();

    // Load the Firebase user object from useAuth
    const { user, isVoyager } = useAuth();

    // Load Stripe features to gain access to the checkout method
    const { stripeService } = useApi();

    const standardMenuItems = navigation.account.map((item, index) => {
        return (
            <MenuItem key={index} color="prussianBlue" onClick={() => router.push(item.url)}>
                {item.label}
            </MenuItem>
        );
    });

    return (
        <React.Fragment>
            <Menu>
                {({ isOpen }) => (
                    <React.Fragment>
                        <MenuButton
                            isActive={isOpen}
                            variant="solid"
                            bgColor="transparent"
                            as={IconButton}
                            icon={
                                isOpen ? (
                                    <CloseIcon boxSize={5} />
                                ) : !isDesktop && userType === "VISITOR" ? (
                                    <HamburgerIcon boxSize={7} />
                                ) : (
                                    <Icon as={CgProfile} boxSize={7} />
                                )
                            }
                            _active={{ bgColor: "transparent" }}
                            _focus={{ bgColor: "transparent" }}
                            _hover={{ bgColor: "transparent" }}
                            transition="none"
                        >
                            {isOpen ? "Close" : "Open"}
                        </MenuButton>
                        <MenuList rounded="none">
                            {userType === "VISITOR" && !isDesktop && (
                                <React.Fragment>
                                    {!isB2b && (
                                        <MenuItem color="prussianBlue" onClick={() => router.push("/join")}>
                                            Join
                                        </MenuItem>
                                    )}
                                    <MenuItem color="prussianBlue" onClick={() => router.push("/login")}>
                                        Log In
                                    </MenuItem>
                                </React.Fragment>
                            )}
                            {userType !== "VISITOR" && (
                                <React.Fragment>
                                    {!isVoyager && !isDesktop && (
                                        <MenuItem
                                            color="prussianBlue"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                stripeService.subscribeToMembership(user);
                                            }}
                                        >
                                            Upgrade
                                        </MenuItem>
                                    )}
                                    {standardMenuItems}
                                </React.Fragment>
                            )}
                        </MenuList>
                    </React.Fragment>
                )}
            </Menu>
        </React.Fragment>
    );
};

export default NavigationDropdown;
