// DO NOT DELETE
export const footer = {
    subsections: [
        {
            header: "About Vacayou",
            links: [
                {
                    text: "About Us",
                    url: "https://vacayou.com/about-us",
                },
                {
                    text: "Careers",
                    url: "https://vacayou.com/information/careers",
                },
                {
                    text: "Contact Us",
                    url: "https://vacayou.com/contact-us",
                },
                {
                    text: "Terms of Use",
                    url: "https://vacayou.com/information/terms-of-use",
                },
                {
                    text: "Privacy Policy",
                    url: "https://vacayou.com/information/privacy-policy",
                },
                {
                    text: "Terms & Conditions",
                    url: "/terms-and-conditions",
                },
            ],
        },
        {
            header: "Become A Partner",
            links: [
                {
                    text: "Resorts & Retreats",
                    url: "https://vacayou.com/partners/resorts-retreats",
                },
                {
                    text: "Tour Providers",
                    url: "https://vacayou.com/partners/tour-providers",
                },
                {
                    text: "Advertisers & Sponsors",
                    url: "https://vacayou.com/partners/advertisers-sponsors",
                },
                {
                    text: "Writers & Influencers",
                    url: "https://vacayou.com/partners/writers-influencers",
                },
                {
                    text: "Destinations",
                    url: "https://vacayou.com/partners/destinations",
                },
            ],
        },
        {
            header: "Traveler Information",
            links: [
                {
                    text: "Travel Insurance",
                    url: "https://vacayou.com/information/travel-insurance",
                },
                {
                    text: "Vacayou Magazine",
                    url: "https://vacayou.com/magazine/",
                },
                {
                    text: "Why Book with Vacayou",
                    url: "https://vacayou.com/why-book-vacayou",
                },
            ],
        },
    ],
};
