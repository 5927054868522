import React, { useContext } from "react";
import { useRouter } from "next/router";
import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { NavigationControls, NavigationLogo } from "..";
import { navigation } from "../../../../../public/content/navigation";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import { UserContext } from "../../../../../contexts/userContext";

const NavigationContainer = () => {
    const router = useRouter();

    const { userState, b2bState } = useContext(UserContext);

    const isLoggedIn = userState.userType !== "VISITOR";

    const isB2b = !!b2bState.organization;

    const isDesktop = useBreakpointValue({ base: false, lg: true });

    return (
        <Box
            as="header"
            id="navigation"
            position={isLoggedIn ? "fixed" : "absolute"}
            w="100%"
            minW="320px"
            zIndex="sticky"
        >
            <Box bg="prussianBlue" color="white" shadow="base">
                <Container maxW="container.xl" pt={4} pb={2} px={{ base: 2, md: 8 }} zIndex="banner">
                    <Flex direction="column">
                        <Box w="100%" mb={2}>
                            <Flex w="100%">
                                {!isDesktop && <Flex flex={1} />}
                                <Flex flex={1} justifyContent={isDesktop ? "flex-start" : "center"} alignItems="center">
                                    <Box h="60px" w="auto" minW="152px">
                                        <NavigationLogo src="/images/vacayou-voyager-logo.svg" />
                                    </Box>
                                </Flex>
                                {isB2b && isDesktop && (
                                    <Flex flex={1} justifyContent="center" alignItems="center">
                                        <Box h="52px" w="auto">
                                            <NavigationLogo src={b2bState.logo.desktop} />
                                        </Box>
                                    </Flex>
                                )}
                                <Flex flex={1} justifyContent="flex-end" alignItems="center">
                                    <NavigationControls
                                        navigation={navigation}
                                        userType={userState.userType}
                                        isB2b={isB2b}
                                        isDesktop={isDesktop}
                                    />
                                </Flex>
                            </Flex>
                            {isB2b && !isDesktop && (
                                <Flex justify="center">
                                    <Box maxW="200px" maxH="52px" h="100%" w="100%" mt={2}>
                                        <NavigationLogo src={b2bState.logo.mobile} />
                                    </Box>
                                </Flex>
                            )}
                        </Box>
                        {userState.userType === "VOYAGER" && (
                            <Tabs
                                variant="unstyled"
                                sx={{
                                    display: "flex",
                                    overflowX: "auto",
                                    justifyContent: { base: "flex-start", md: "center" },
                                    alignItems: "center",
                                }}
                            >
                                <TabList
                                    overflowX="auto"
                                    flexWrap="nowrap"
                                    sx={{
                                        WebkitOverflowScrolling: "touch",
                                        msOverflowStyle: "-ms-autohiding-scrollbar",
                                        scrollbarWidth: "none",
                                        "::-webkit-scrollbar": {
                                            display: "none",
                                        },
                                    }}
                                >
                                    {navigation.voyager.map((item, index) => {
                                        return (
                                            <Tab
                                                key={index}
                                                textTransform="uppercase"
                                                flexShrink={0}
                                                onClick={() => router.push(item.url)}
                                                _active={{ textUnderlineOffset: "12px", textDecoration: "underline" }}
                                                _hover={{ textUnderlineOffset: "12px", textDecoration: "underline" }}
                                                _focus={{ boxShadow: "none" }}
                                                sx={
                                                    router.route === item.url && {
                                                        textUnderlineOffset: "12px",
                                                        textDecoration: "underline",
                                                    }
                                                }
                                            >
                                                {item.label}
                                            </Tab>
                                        );
                                    })}
                                </TabList>
                            </Tabs>
                        )}
                    </Flex>
                </Container>
            </Box>
        </Box>
    );
};

export default NavigationContainer;
