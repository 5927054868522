import { Icon, Link } from "@chakra-ui/react";
import { CgFacebook, CgInstagram } from "react-icons/cg";
import { TiSocialTwitter } from "react-icons/ti";
import { GrLinkedinOption } from "react-icons/gr";
import { FaPinterestP } from "react-icons/fa";

const FooterIcons = () => {
    const socialMediaList = [
        {
            name: "instagram",
            link: "https://www.instagram.com/vacayoutravel/",
            icon: <Icon as={CgInstagram} />,
        },
        {
            name: "twitter",
            link: "https://twitter.com/vacayoutravel",
            icon: <Icon as={TiSocialTwitter} />,
        },
        {
            name: "facebook",
            link: "https://www.facebook.com/vacayoutravel/",
            icon: <Icon as={CgFacebook} />,
        },
        {
            name: "linkedin",
            link: "https://www.linkedin.com/company/vacayoutravel/mycompany/",
            icon: <Icon as={GrLinkedinOption} />,
        },
        {
            name: "pinterest",
            link: "https://www.pinterest.com/vacayoutravel/",
            icon: <Icon as={FaPinterestP} />,
        },
    ];

    const socialMedia = socialMediaList.map((socialMediaListItem) => (
        <Link
            key={socialMediaListItem.name}
            display="inline-block"
            h="16px"
            w="16px"
            m={{ base: 2, md: 3 }}
            href={socialMediaListItem.link}
            target="_blank"
        >
            {socialMediaListItem.icon}
        </Link>
    ));

    return <React.Fragment>{socialMedia}</React.Fragment>;
};

export default FooterIcons;
