import NextLink from "next/link";
import { Image, Link } from "@chakra-ui/react";

const NavigationLogo = ({ src }) => {
    return (
        <NextLink href="/" passHref>
            <Link>
                <Image maxW="100%" maxH="100%" h="100%" w="100%" src={src} />
            </Link>
        </NextLink>
    );
};

export default NavigationLogo;
