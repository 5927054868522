// DO NOT DELETE
export const navigation = {
    voyager: [
        {
            label: "Getaways",
            url: "/trips",
        },
        {
            label: "Hotels",
            url: "/hotels",
        },
        {
            label: "Perks",
            url: "/perks",
        },
        {
            label: "Videos",
            url: "/videos",
        },
        // {
        //     label: "Wellness This Week",
        //     url: "/wellness-this-week",
        // },
    ],
    account: [
        {
            label: "Account",
            url: "/account/settings",
        },
        {
            label: "833.822.2968",
            url: "tel:+18338222968",
        },
        {
            label: "Chat With Us",
            url: "#hs-chat-open",
        },
        {
            label: "Log Out",
            url: "/account/logout",
        },
    ],
};
