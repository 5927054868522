import { compose, withProps } from "recompose";
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from "react-google-maps";

const MapTile = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyBYfg8C1Oq8kz3fWWT9HCndh05ZAXT75V8&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    const { coords, isMarkerShown } = props;

    return (
        <GoogleMap defaultZoom={10} defaultCenter={{ lat: +coords.lat, lng: +coords.lng }}>
            {isMarkerShown && <Marker position={{ lat: +coords.lat, lng: +coords.lng }} />}
        </GoogleMap>
    );
});

export default MapTile;
