import React from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { Link, Stack } from "@chakra-ui/react";
import { NavigationDropdown } from "..";
import { SignUpButton } from "../../../../common";

const NavigationControls = ({ navigation, userType, isB2b, isDesktop }) => {
    const router = useRouter();

    return (
        <Stack spacing={4} direction="row" align="center">
            {isDesktop && (
                <React.Fragment>
                    {!isB2b && userType !== "VOYAGER" && <SignUpButton color="white" colorScheme="gold" />}
                    {userType === "VISITOR" && (
                        <NextLink href={"/login"} passHref>
                            <Link
                                textTransform="uppercase"
                                fontWeight="semibold"
                                sx={
                                    router.route === "/login" && {
                                        textUnderlineOffset: "12px",
                                        textDecoration: "underline",
                                    }
                                }
                                _hover={{ textUnderlineOffset: "12px", textDecoration: "underline" }}
                            >
                                Log In
                            </Link>
                        </NextLink>
                    )}
                </React.Fragment>
            )}
            {((userType !== "VISITOR" && isDesktop) || !isDesktop) && (
                <NavigationDropdown navigation={navigation} userType={userType} isB2b={isB2b} isDesktop={isDesktop} />
            )}
        </Stack>
    );
};

export default NavigationControls;
